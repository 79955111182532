






import {Component, Vue} from "vue-property-decorator";

@Component({
    data() {
        const title = "About ComtéLabs";
        const width = window.innerWidth;
        const height = window.innerHeight * 0.6;

        return {
            title,
            width,
            height,
        };
    },
})
export default class About extends Vue {
}
